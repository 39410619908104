import "./LoginForm.css";
import ConnectWallet from "../../common/connectWallet/ConnectWallet";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { setPreSignUpRegister } from "../../../store/globalActions";

const LoginForm: React.FC = () => {
  const { globalDispatch } = useContext(GlobalContext);

  return (
    <>
      <div className="loginFormContainer">
        <button
          className="loginFormButton registerButton"
          onClick={() => globalDispatch(setPreSignUpRegister(true))}
        >
          REGISTER NOW
        </button>
        <ConnectWallet className="loginFormButton connectWalletButton"></ConnectWallet>
      </div>
    </>
  );
};

export default LoginForm;
