import React from "react";
import PhaseBox from "../phaseBox/PhaseBox";
import "./PhaseBoxGroup.css";
const PhaseBoxGroup: React.FC = () => {
  return (
    <div className="phaseGroup">
      <PhaseBox
        frontTitle="PHASE 1"
        frontDescription="Pre-launch Phase: Countdown to KII-BOOM"
        isNext={true}
        backDescription="Reward for registering in the pre-sign up and receive additional KII for recruiting more ships."
      />
      <PhaseBox
        frontTitle="PHASE 2"
        frontDescription="Explosive Phase: The Big Bang"
        disabled={true}
        backDescription="Ignite your crypto journey, pilot your ship, clear missions for explosive rewards, and make your wallet KII-BOOM."
      />
      <PhaseBox
        frontTitle="PHASE 3"
        frontDescription="Mission Phase: Navigate the Stars"
        disabled={true}
        backDescription="New missions await. To be a part of the crew it is essential that you get NFTs from Latam’s Marketplace Planet."
      />
      <PhaseBox
        frontTitle="PHASE 4"
        frontDescription="Discovery Phase: New Treasures Unveiling"
        disabled={true}
        backDescription="The entire galaxy will rattle from the cosmic blast revealing hidden treasures."
      />
      <PhaseBox
        frontTitle="PHASE 5"
        frontDescription="Celebration Phase: Galactic Glory"
        disabled={true}
        backDescription="A real explosion and the riskiest missions will determine the true glory for the crypto galaxy."
      />
    </div>
  );
};

export default PhaseBoxGroup;
