import "./PhaseIndicator.css";
import circle from "../../../assets/images/circle-phase.png";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

const PhaseIndicator: React.FC = () => {
  const { globalStore } = useContext(GlobalContext);

  return (
    <div className="phase-indicator">
      <div
        className="circle"
        style={{
          backgroundImage: `conic-gradient(
                          rgba(122, 51, 255, 0.9) ${globalStore.application.console.phasePercentage}deg,
                          rgba(255, 255, 255, 0) ${globalStore.application.console.phasePercentage}deg
                        )`,
        }}
      >
        <div className="phase-completed">
          {globalStore.application.console.phasePercentage}% phase completed
        </div>
        <img src={circle} className="circle-background" />
      </div>

      <div className="text-phase-indicator">
        You’re curently on Explosive Phase: The Big Bang.
      </div>
    </div>
  );
};

export default PhaseIndicator;
