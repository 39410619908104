import React, { useContext, useReducer, useState } from "react";
import "./PresigningForm.css";
import { UserData } from "../../../interfaces/user";
import { useContract } from "../../../hooks/useContract";
import { useNavigate } from "react-router-dom";
import { generateReferralCode } from "../../../utils/validateForm";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  setAppLoaded,
  setAppLoading,
  setPreSignUpRegister,
} from "../../../store/globalActions";

interface props {
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAlreadyRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}

const PresigningForm: React.FC<props> = ({
  setIsModalActive,
  setIsAlreadyRegistered,
}) => {
  const { registerUser, getUser } = useContract();
  const navigate = useNavigate();
  const { globalStore, globalDispatch } = useContext(GlobalContext);

  const [formState, setFormState] = useState<UserData>({
    shipName: globalStore.user.shipName,
    walletAddress: globalStore.user.userAddress,
    referralCode: "",
    agreeTerms: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Start loading pagen and make smart contract's request
    globalDispatch(setAppLoading());

    // Check if user is already registered
    const userData = await getUser(formState.walletAddress);

    if (userData?.agreeTerms) {
      globalDispatch(setAppLoaded());
      setIsAlreadyRegistered(true);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setIsAlreadyRegistered(false);
      navigate("/coming", { state: { formState } });
      return;
    }

    // Create referrar code
    const code = generateReferralCode();
    formState.referralCode = code;
    const success = await registerUser(formState);

    // Stop loading and redirect if request was success
    // globalDispatch(setAppLoaded());
    if (success) {
      navigate("/coming", { state: { formState } });
      return;
    }

    // Show error message
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label
          className="form-label"
          onClick={() => globalDispatch(setPreSignUpRegister(false))}
        >
          Return
        </label>

        <input
          type="text"
          name="walletAddress"
          placeholder="Wallet address"
          value={formState.walletAddress}
          onChange={handleChange}
          className="form-input"
          required
        />
        <input
          type="text"
          name="shipName"
          placeholder="Ship Name"
          value={formState.shipName}
          onChange={handleChange}
          className="form-input"
          required
        />
        <div className="advice-message">
          <label>
            You must choose a name for your ship with which you will use do the
            KII-BOOM missions
          </label>
        </div>
        <div className="checkbox-group">
          <label>Agree T&C</label>
          <input
            type="checkbox"
            name="agreeTerms"
            id="agreeTerms"
            checked={formState.agreeTerms}
            onChange={handleChange}
            required
          />
          <label htmlFor="agreeTerms" className="checkbox-label">
            <a href="#" onClick={() => setIsModalActive(true)}>
              Terms and conditions
            </a>
          </label>
        </div>
        <button type="submit" className="submit-button">
          <div className="submit-title">Register Now</div>
          <div className="submit-description">
            Pre-register for Incentivized Testnet
          </div>
        </button>
        <div className="advice-message"></div>
      </div>
    </form>
  );
};

export default PresigningForm;
