import React from "react";
import logo from "../../../assets/images/logo.png";
import "./Header.css";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="header">
      <Link to={"/"}>
        <img src={logo} alt="KiiChain Logo" className="header-logo" />
      </Link>
    </header>
  );
};

export default Header;
