import React, { useState } from "react";
import "./PhaseAnnounce.css"; // Importa el archivo CSS
import { UserData } from "../../../interfaces/user";
import { useContract } from "../../../hooks/useContract";

interface props {
  formState: UserData;
}

const PhaseAnnounce: React.FC<props> = ({ formState }) => {
  const { registerUser, getUser } = useContract();
  const [referralMessage, setReferralMessage] = useState<string>(
    "CREATE YOUR REFERRAL CODE"
  );
  const [copied, setCopied] = useState(false);

  const handleReferrarCode = async () => {
    try {
      const data = await getUser(formState.walletAddress);
      if (data?.agreeTerms) {
        setReferralMessage(data.referralCode);
        try {
          await navigator.clipboard.writeText(data.referralCode);
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 2000);
        } catch (err) {
          console.error("Error copying the text: ", err);
        }
      }
    } catch (error) {
      console.error("Error trying to get the referrar code: ", error);
    }
  };

  return (
    <div className="phase-announcement-container">
      <div className="announcement-text">
        <p className="announcement-highlight">
          September 23rd will be our first explosion and start of phase 2:
        </p>

        <p className="announcement-description">
          The Big Bang with the first missions. Create a referral code for other
          ships and earn more rewards.
        </p>
      </div>
      <button className="custom-button" onClick={handleReferrarCode}>
        <div className="custom-button-text-container">{referralMessage}</div>
      </button>
      {copied && <div className="copy-notification">Code copied!</div>}
    </div>
  );
};

export default PhaseAnnounce;
