import { Action, GlobalStore } from "../interfaces/store";
import * as actions from "./globalActions";
import GlobalInitStore from "./globalStore";

export const GlobalReducer = (
  InitialState: GlobalStore = GlobalInitStore,
  action: Action
): GlobalStore => {
  switch (action.type) {
    // Application Actions
    case actions.GLOBAL_SET_APP_LOADING:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          loading: true, // Application loading
          success: false,
        },
      };

    case actions.GLOBAL_SET_APP_LOADED:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          loading: false, // Application loaded
          success: true,
        },
      };

    case actions.GLOBAL_SET_APP_ERROR:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          error: true, // Error
          errorMessage: action.payload, // Save error message
          success: false, // Error - Not success
        },
      };

    case actions.GLOBAL_SET_APP_SECTION:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          section: action.payload, // Section updated
        },
      };

    // User Actions
    case actions.GLOBAL_SET_USER_ADDRESS:
      return {
        ...InitialState,
        user: {
          ...InitialState.user,
          userAddress: action.payload, // Address updated
        },
      };

    case actions.GLOBAL_SET_USER_INKII_BALANCE:
      return {
        ...InitialState,
        user: {
          ...InitialState.user,
          userInkiiBalance: action.payload, // Inkii Balance Updated
        },
      };

    case actions.GLOBAL_SET_USER_KII_BALANCE:
      return {
        ...InitialState,
        user: {
          ...InitialState.user,
          userKiiBalance: action.payload, // Kii Balance Updated
        },
      };

    case actions.GLOBAL_SET_USER_NFTS:
      return {
        ...InitialState,
        user: {
          ...InitialState.user,
          userNFTs: action.payload, // NFT Collection Updated
        },
      };

    case actions.GLOBAL_SET_USER_SHIP_NAME:
      return {
        ...InitialState,
        user: {
          ...InitialState.user,
          shipName: action.payload, // Update ship name
        },
      };

    // Console Actions
    case actions.GLOBAL_SET_PHASE_PROGRESS:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          console: {
            ...InitialState.application.console,
            phasePercentage: action.payload, // Update the phase progress
          },
        },
      };

    // Pre signUp actions
    case actions.GLOBAL_SET_PRESIGNUP_REGISTER:
      return {
        ...InitialState,
        application: {
          ...InitialState.application,
          preSignUp: {
            register: action.payload, // Update register flag
          },
        },
      };

    // Default
    default:
      return InitialState;
  }
};
