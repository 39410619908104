import { createWeb3Modal, defaultConfig } from "@web3modal/ethers";
import { createContext, ReactNode, useEffect, useState } from "react";

import { AppKit } from "@web3modal/base";
import { EthersStoreUtilState } from "@web3modal/scaffold-utils/ethers";

interface props {
  modal: AppKit<EthersStoreUtilState, number> | undefined;
  isConnected: () => boolean;
}

// Create context
export const web3ModalContext = createContext<props>({
  modal: undefined,
  isConnected: () => false,
});

// Create context provider
export const WalletConnectContext = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<AppKit<EthersStoreUtilState, number>>();

  useEffect(() => {
    const projectId = "7c36e3d2b57283b86bc9aa59ce27e98a";

    const metadata = {
      name: "kiichain airdrop",
      description: "kiichain airdrop",
      url: "https://airdrop.kiichain.io/",
      icons: ["https://airdrop.kiichain.io/"],
    };

    const kiichain = {
      chainId: 123454321,
      name: "Kiichain Testnet",
      currency: "kii",
      explorerUrl: "https://app.kiichain.io/kiichain",
      rpcUrl: "https://a.sentry.testnet.kiivalidator.com:8645",
    };

    const ethersConfig = defaultConfig({
      metadata,
      rpcUrl: "https://a.sentry.testnet.kiivalidator.com:8645",
      defaultChainId: 1,
    });

    const modalInstance = createWeb3Modal({
      ethersConfig,
      chains: [kiichain],
      projectId,
      enableAnalytics: true,
    });

    setModal(modalInstance);
  }, []);

  function isConnected(): boolean {
    const data = modal?.getIsConnectedState();
    return data!;
  }

  return (
    <web3ModalContext.Provider value={{ modal: modal!, isConnected }}>
      {children}
    </web3ModalContext.Provider>
  );
};
