import { termsAndConditions } from "../../../data/t&c";
import "./PresignedModal.css";

interface props {
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PresignedModal: React.FC<props> = ({ setIsModalActive }) => {
  const handleModal = (value: boolean): void => {
    setIsModalActive(false); // Close the modal even the tyc were rejected
  };

  return (
    <>
      <div className="modalContainer">
        <div className="modalTitle">
          <h1>KII-BOOM</h1>
        </div>

        <div className="modalContent">
          <label htmlFor="termsConditions">Terms & Conditions</label>
        </div>

        <div className="textContainer">
          <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
        </div>

        <div className="buttonContainer">
          <button className="cancelButton" onClick={() => handleModal(false)}>
            Cancel
          </button>
          <button className="acceptButton" onClick={() => handleModal(true)}>
            Approve
          </button>
        </div>
      </div>
    </>
  );
};

export default PresignedModal;
