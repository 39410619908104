import "./CoinGif.css";

const CoinGif: React.FC = () => {
  return (
    <>
      <div className="coin-container">
        <img
          className="coin-img"
          src="https://airdrop-page.s3.us-east-2.amazonaws.com/welcome-video/KII_GIF.gif"
          alt="Coin rotating"
        />
      </div>
    </>
  );
};

export default CoinGif;
