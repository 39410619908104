interface FormState {
  shipName: string;
  walletAddress: string;
  referralCode: string;
  agreeTerms: boolean;
}

interface FormErrors {
  shipName?: string;
  walletAddress?: string;
  referralCode?: string;
  agreeTerms?: string;
}

export const validateForm = (formData: FormState): FormErrors => {
  const errors: FormErrors = {};

  if (!formData.shipName) errors.shipName = "Ship Name is required";
  if (!formData.walletAddress)
    errors.walletAddress = "Wallet address is required";
  if (formData.agreeTerms != true)
    errors.agreeTerms = "You must agree to the terms";

  return errors;
};

export function generateReferralCode(): string {
  // Get timestamp and convert that number into a character
  const timestamp = Date.now().toString();
  let referralCode = Array.from(timestamp)
    .map((digit) => {
      const num = parseInt(digit, 10); // Convert to string to number
      if (num % 2 === 0) {
        // Odd number are in lowercase, even are in uppercase
        return String.fromCharCode(65 + num);
      } else {
        return String.fromCharCode(97 + num);
      }
    })
    .join("");
  // separate and Group by 3 characters "ABC-dEF-gHi-JkL-mNO"
  referralCode = referralCode.match(/.{1,3}/g)?.join("-") || "";

  return referralCode;
}
