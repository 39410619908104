import { useContext } from "react";
import { web3ModalContext } from "../../../contexts/WalletConnectContext";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  setAppError,
  setPreSignUpRegister,
  setUserAddress,
  setUserKiiBalance,
  setUserShipName,
} from "../../../store/globalActions";
import { useContract } from "../../../hooks/useContract";
import { BrowserProvider, ethers } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";

interface props {
  className: string;
}

const ConnectWallet: React.FC<props> = ({ className }) => {
  const modalContext = useContext(web3ModalContext);
  const modal = modalContext?.modal;
  const { globalDispatch } = useContext(GlobalContext);
  const { getUser } = useContract();
  const { walletProvider } = useWeb3ModalProvider();

  async function connectWallet() {
    try {
      await modal?.open();

      let isConnected = modal?.getIsConnectedState();
      const timeout = 30000; // max 30s to login
      const interval = 500; // each 500ms check whether the user login
      let elapsedTime = 0;

      while (!isConnected && elapsedTime < timeout) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        isConnected = modal?.getIsConnectedState();
        elapsedTime += interval;
      }

      if (isConnected) {
        // Create provider and signer
        const provider = new BrowserProvider(walletProvider!);
        const signer = await provider.getSigner();

        // Get wallet address
        const walletAddress = await signer.getAddress();
        globalDispatch(setUserAddress(walletAddress));

        // Get Kii balance
        const balance = (await provider.getBalance(walletAddress)).toString();
        globalDispatch(setUserKiiBalance(balance));

        // Get ship name
        const userData = await getUser(walletAddress!);
        if (!userData?.agreeTerms) {
          console.error("Error getting the user information.");
          globalDispatch(setAppError("Error getting the user information."));
        }

        globalDispatch(setUserShipName(userData?.shipName!));
        globalDispatch(setPreSignUpRegister(true));

        return;
      } else {
        console.log("The time for login has finished.");
        globalDispatch(setAppError("The time for login has finished."));
      }
    } catch (error) {
      console.error("Error openning Wallet Connect modal", error);
      globalDispatch(
        setAppError(`Error openning Wallet Connect modal, ${error}`)
      );
    }
  }
  return (
    <button className={className} onClick={connectWallet}>
      CONNECT WALLET
    </button>
  );
};

export default ConnectWallet;
