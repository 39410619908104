import React, { useState } from "react";
import "./PhaseBox.css";

interface BoxProps {
  frontTitle: string;
  frontDescription: string;
  backDescription: string;
  disabled?: boolean;
  isNext?: boolean;
}

const PhaseBox: React.FC<BoxProps> = ({
  frontTitle,
  frontDescription,
  backDescription,
  disabled,
  isNext,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      className={`phaseBox-container ${disabled ? "disabled" : ""} ${
        isNext ? "isNext" : ""
      }`}
    >
      <div
        className={`phaseBox ${disabled ? "disabled" : ""} ${
          isNext ? "isNext" : ""
        } ${isFlipped ? "flipped" : ""}`}
      >
        {/* Front Part */}
        <div className="phaseBox-front">
          <div className="phaseBox-title">{frontTitle}</div>
          <div className="phaseBox-description">{frontDescription}</div>
        </div>

        {/* Back part */}
        <div className="phaseBox-back">
          <div className="phaseBox-description">{backDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default PhaseBox;
