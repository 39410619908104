import "./ConsolePage.css";
import ConsoleElement from "../../components/console/consoleElement/ConsoleElement";
import ConsoleButton, {
  Positions,
} from "../../components/console/consoleButton/ConsoleButton";
import { useContext, useEffect, useState } from "react";
import monedaKii from "../../assets/images/MONEDA KII.png";
import Referal from "../../components/console/referal/Referal";
import { GlobalContext } from "../../contexts/GlobalContext";
import { setAppSection } from "../../store/globalActions";
import ConnectWallet from "../../components/common/connectWallet/ConnectWallet";
import PhaseIndicator from "../../components/console/phaseIndicator/PhaseIndicator";

const ConsolePage: React.FC = () => {
  const [useVW, setUseVW] = useState<boolean>(
    window.innerWidth / window.innerHeight > 16 / 9
  );
  const { globalDispatch } = useContext(GlobalContext);

  useEffect(() => {
    globalDispatch(setAppSection("Console"));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newAspectRatio = window.innerWidth / window.innerHeight;
      const newUseVW = newAspectRatio > 16 / 9;

      if (newUseVW !== useVW) {
        setUseVW(newUseVW);
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [useVW]);
  const calculateDimension = (vhValue: number, vwValue: number) => {
    return useVW ? `${vwValue}vw` : `${vhValue}vh`;
  };

  return (
    <div className="console-page-container">
      <div className="background-console"></div>
      <div className="text">
        <p>
          KII-BOOM! KII’s Biggest Airdrop, a real explosion. Earn KII by
          completing the following tasks.
        </p>
      </div>

      <ConsoleElement
        width={calculateDimension(177.77, 100)}
        height={calculateDimension(34.62, 19.47)}
        top={calculateDimension(65.27, 36.71)}
        left="0px"
        elements={[
          {
            component: (
              <ConsoleButton
                insideText="KIIX"
                outsideText="Claim Testnet Assets"
                outsideTextPosition={Positions.LEFT}
                onClickHandler={() => {}}
                layout={{
                  width: calculateDimension(10.09, 5.67),
                  height: calculateDimension(6.38, 3.59),
                }}
              />
            ),
            bottom: calculateDimension(13.88, 7.81),
            left: calculateDimension(13.24, 7.44),
          },
          {
            component: (
              <img
                src={monedaKii}
                alt="KII Coin"
                style={{
                  width: calculateDimension(44.07, 24.79),
                  height: calculateDimension(24.81, 13.95),
                }}
              />
            ),
            bottom: calculateDimension(9.72, 5.46),
            left: calculateDimension(91.85, 51.66),
          },
          {
            component: (
              <ConsoleButton
                insideText="HELP"
                outsideText="Need help with your ship?"
                outsideTextPosition={Positions.RIGHT}
                onClickHandler={() => {}}
                layout={{
                  width: calculateDimension(12.51, 7.03),
                  height: calculateDimension(6.38, 3.59),
                }}
              />
            ),
            bottom: calculateDimension(13.88, 7.81),
            left: calculateDimension(143.98, 80.98),
          },
        ]}
      />
      <ConsoleElement
        width={calculateDimension(27.77, 15.62)}
        height={calculateDimension(32.4, 18.22)}
        top={calculateDimension(40.74, 22.91)}
        left={calculateDimension(74.07, 41.66)}
        elements={[
          {
            component: <ConnectWallet className="connect-wallet-button" />,
            bottom: "50px",
            left: "0px",
          },
        ]}
      />
      <ConsoleElement
        width={calculateDimension(39.81, 22.39)}
        height={calculateDimension(32.4, 18.22)}
        top={calculateDimension(41.66, 23.43)}
        left={calculateDimension(3.7, 2.08)}
        rotation="-10deg"
        elements={[
          {
            component: <PhaseIndicator />,
            bottom: calculateDimension(0.92, 0.52),
            left: calculateDimension(9.2, 5.2),
          },
        ]}
      />
      <ConsoleElement
        width={calculateDimension(29.16, 16.4)}
        height={calculateDimension(16.66, 9.37)}
        top={calculateDimension(41.66, 23.43)}
        left={calculateDimension(106.48, 59.89)}
        rotation="3.8deg" //4
        elements={[
          {
            component: <Referal />,
            bottom: "40px", //calculateDimension(13.88, 7.81),
            left: "20px", //calculateDimension(13.24, 7.44),
          },
        ]}
      />
    </div>
  );
};

export default ConsolePage;
