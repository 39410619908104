import { GlobalStore } from "../interfaces/store";

const GlobalInitStore: GlobalStore = {
  user: {
    userAddress: "",
    userInkiiBalance: "",
    userKiiBalance: "",
    userNFTs: [],
    shipName: "",
  },
  application: {
    error: false,
    errorMessage: "",
    loading: false,
    success: false,
    section: "",
    preSignUp: {
      register: false,
    },
    console: {
      isOpen: false,
      stage: 0,
      phasePercentage: 0,
    },
  },
};

export default GlobalInitStore;
