export const termsAndConditions = `
<h1>KII-BOOM Airdrop Terms and Conditions</h1>
<ol>
  <li>
    <strong>Definitions</strong>
    <ul>
      <li>
        <strong>"Airdrop"</strong>: The event for distributing KII tokens to
        promote the adoption of the KiiChain blockchain among participants
        completing missions.
      </li>
      <li>
        <strong>"KII GLOBAL S.A.S."</strong>: The entity responsible for the
        management of the Airdrop and KiiChain.
      </li>
      <li>
        <strong>"KII Token"</strong>: The native token of the KiiChain
        blockchain, distributed as a reward to participants.
      </li>
      <li>
        <strong>"Special Token for Missions"</strong>: A token created
        exclusively for mission participation in the Airdrop, without any
        transactional value on the Mainnet.
      </li>
      <li>
        <strong>"Faucet"</strong>: A mechanism allowing participants to claim
        the Special Token for Missions every 24 hours.
      </li>
    </ul>
  </li>
  <li>
    <strong>Purpose of the Airdrop</strong>
    <p>
      The KII-BOOM Airdrop aims to distribute 36 million KII tokens to
      participants completing gamified activities and missions. The Airdrop will
      take place over multiple phases, with special events known as Explosions
      between phases. These Explosions will reward additional KII tokens to the
      participants with the best performance and participation.
    </p>
  </li>
  <li>
    <strong
      >Difference Between KII Token and the Special Token for Missions</strong
    >
    <p>
      Participants will interact with two different tokens during the Airdrop:
    </p>
    <ul>
      <li>
        <strong>KII Token</strong>: The native token of KiiChain, holding
        transactional value, which will be distributed as rewards. This token
        will be usable within the ecosystem and will have value on the Mainnet
        after launch.
      </li>
      <li>
        <strong>Special Token for Missions</strong>: A token created solely for
        completing missions within the Airdrop. This token can be claimed
        through the faucet and is necessary to complete certain tasks that will
        unlock KII Token rewards, but it does not hold any transactional value
        or utility on the Mainnet.
      </li>
    </ul>
  </li>
  <li>
    <strong>Participation and Eligibility</strong>
    <p>To participate in the KII-BOOM Airdrop, participants must:</p>
    <ul>
      <li>Be at least 18 years old.</li>
      <li>
        Register a compatible wallet address with KiiChain, which cannot be
        changed after registration.
      </li>
      <li>Complete the daily and periodic missions provided.</li>
    </ul>
  </li>
  <li>
    <strong>Phases and Explosions</strong>
    <p>
      The Airdrop will progress through different phases, which will be unlocked
      over time. At the end of each phase, an Explosion will take place, where
      the most active participants will receive additional rewards in KII
      Tokens.
    </p>
  </li>
  <li>
    <strong>Claiming Tokens through the Faucet</strong>
    <p>
      Every 24 hours, participants can claim a limited amount of the Special
      Token for Missions via the faucet. This token is necessary for completing
      specific activities within the Airdrop but holds no value outside this
      context.
    </p>
  </li>
  <li>
    <strong>Rewards and Token Distribution</strong>
    <p>
      Rewards in KII Tokens will depend on participants' engagement and
      performance in missions. Explosions will grant additional rewards to the
      top performers.
    </p>
  </li>
  <li>
    <strong>Modifications and Cancellation</strong>
    <p>
      KiiChain reserves the right to modify, adjust, or cancel the Airdrop at
      any time without prior notice.
    </p>
  </li>
  <li>
    <strong>Fraud and Disqualification</strong>
    <p>
      The use of bots or any fraudulent activities will result in immediate
      disqualification from the Airdrop.
    </p>
  </li>
  <li>
    <strong>Global Jurisdiction and Decentralized Nature</strong>
    <p>
      This Airdrop is governed by the rules and principles of the global
      blockchain ecosystem, with no specific national jurisdiction applicable.
    </p>
  </li>
  <li>
    <strong>Liability Exclusion</strong>
    <p>
      KiiChain will not be held liable for any token losses, network failures,
      or other inconveniences resulting from participation in the Airdrop.
    </p>
  </li>
  <li>
    <strong>Acceptance of Terms</strong>
    <p>
      By registering and participating, users accept these terms and conditions
      in full.
    </p>
  </li>
</ol>

<h1>Términos y Condiciones del Airdrop KII-BOOM</h1>
<ol>
  <li>
    <strong>Definiciones</strong>
    <ul>
      <li>
        <strong>Airdrop</strong>: Evento para la distribución de tokens KII con
        el fin de promover la adopción de la blockchain KiiChain entre los
        participantes que completen misiones.
      </li>
      <li>
        <strong>KII GLOBAL S.A.S.</strong>: Entidad responsable de la gestión
        del Airdrop y KiiChain.
      </li>
      <li>
        <strong>Token KII</strong>: Token nativo de la blockchain KiiChain,
        distribuido como recompensa a los participantes.
      </li>
      <li>
        <strong>Token Especial para Misiones</strong>: Token creado
        exclusivamente para la participación en las misiones del Airdrop, sin
        valor transaccional en Mainnet.
      </li>
      <li>
        <strong>Faucet</strong>: Mecanismo que permite a los participantes
        reclamar el Token Especial para Misiones cada 24 horas.
      </li>
    </ul>
  </li>
  <li>
    <strong>Propósito del Airdrop</strong>
    <p>
      El Airdrop KII-BOOM tiene como objetivo distribuir 36 millones de tokens
      KII a los participantes que completen actividades gamificadas y misiones.
      El Airdrop se llevará a cabo en múltiples fases, con eventos especiales
      llamados Explosiones entre fases. Estas Explosiones otorgarán tokens KII
      adicionales a los participantes con el mejor rendimiento y participación.
    </p>
  </li>
  <li>
    <strong
      >Diferencia entre el Token KII y el Token Especial para Misiones</strong
    >
    <p>
      Durante el Airdrop, los participantes interactuarán con dos tipos de
      tokens:
    </p>
    <ul>
      <li>
        <strong>Token KII</strong>: Token nativo de KiiChain, con valor
        transaccional, que se distribuirá como recompensa. Este token será
        utilizable dentro del ecosistema y tendrá valor en la Mainnet una vez
        lanzada.
      </li>
      <li>
        <strong>Token Especial para Misiones</strong>: Token creado
        exclusivamente para completar misiones dentro del Airdrop. Este token se
        podrá reclamar a través del faucet y es necesario para realizar ciertas
        tareas que desbloquean recompensas en tokens KII, pero no tiene valor
        transaccional ni utilidad en la Mainnet.
      </li>
    </ul>
  </li>
  <li>
    <strong>Participación y Elegibilidad</strong>
    <p>Para participar en el Airdrop KII-BOOM, los participantes deben:</p>
    <ul>
      <li>Ser mayores de 18 años.</li>
      <li>
        Registrar una dirección de billetera compatible con KiiChain, la cual no
        podrá cambiarse después del registro.
      </li>
      <li>Completar las misiones diarias y periódicas proporcionadas.</li>
    </ul>
  </li>
  <li>
    <strong>Fases y Explosiones</strong>
    <p>
      El Airdrop progresará a través de diferentes fases, que se irán
      desbloqueando con el tiempo. Al final de cada fase, se llevará a cabo una
      Explosión, en la que los participantes más activos recibirán recompensas
      adicionales en tokens KII.
    </p>
  </li>
  <li>
    <strong>Reclamo de Tokens a través del Faucet</strong>
    <p>
      Cada 24 horas, los participantes podrán reclamar una cantidad limitada de
      Token Especial para Misiones a través del faucet. Este token es necesario
      para completar actividades específicas dentro del Airdrop, pero no tiene
      valor fuera de este contexto.
    </p>
  </li>
  <li>
    <strong>Recompensas y Distribución de Tokens</strong>
    <p>
      Las recompensas en tokens KII dependerán del nivel de participación y
      desempeño en las misiones. Las Explosiones otorgarán recompensas
      adicionales a los participantes con mejores resultados.
    </p>
  </li>
  <li>
    <strong>Modificaciones y Cancelación</strong>
    <p>
      KiiChain se reserva el derecho de modificar, ajustar o cancelar el Airdrop
      en cualquier momento sin previo aviso.
    </p>
  </li>
  <li>
    <strong>Fraude y Descalificación</strong>
    <p>
      El uso de bots o cualquier actividad fraudulenta resultará en la
      descalificación inmediata del Airdrop.
    </p>
  </li>
  <li>
    <strong>Jurisdicción Global y Naturaleza Descentralizada</strong>
    <p>
      Este Airdrop se rige por las reglas y principios del ecosistema global de
      blockchain, sin que se aplique una jurisdicción nacional específica.
    </p>
  </li>
  <li>
    <strong>Exclusión de Responsabilidad</strong>
    <p>
      KiiChain no se responsabiliza por pérdidas de tokens, fallos en la red u
      otros inconvenientes derivados de la participación en el Airdrop.
    </p>
  </li>
  <li>
    <strong>Aceptación de los Términos</strong>
    <p>
      Al registrarse y participar, los usuarios aceptan estos términos y
      condiciones en su totalidad.
    </p>
  </li>
</ol>
`;
