import React from "react";
import "./RewardMessage.css"; // Importa el archivo CSS

const RewardMessage: React.FC = () => {
  return (
    <div className="reward-message-container">
      <div className="reward-text-group">
        <p className="reward-message-text">You have earned</p>
        <p className="reward-message-highlight">+1 KII</p>
        <p className="reward-message-subtext">
          for registering to our Pre Sing Up
        </p>
      </div>

      <p className="info-text">
        *All the KIIS you earn during the pre sing up for registering first, and
        for the ships referred with your code will be available in your wallet
        once phase 2 of the airdrop starts.
      </p>
    </div>
  );
};

export default RewardMessage;
