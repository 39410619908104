import React, { useState } from "react";
import "./FAQ.css"; // Import the updated CSS
import data from "../../../data/faqItems.json";
interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = data;

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-title">
        <h2>FAQ</h2>
      </div>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div
            className={`faq-question ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <strong>{item.question}</strong>
            <span className="faq-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d={openIndex === index ? "m17 14l-5-5l-5 5" : "m7 10l5 5l5-5"}
                />
              </svg>
            </span>
          </div>
          {openIndex === index && (
            <div
              className="faq-answer"
              dangerouslySetInnerHTML={{ __html: item.answer }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
