import { ethers } from "ethers";

export function getProvider(): ethers.JsonRpcProvider {
  const url = "https://a.sentry.testnet.kiivalidator.com:8645";
  const provider = new ethers.JsonRpcProvider(url);
  return provider;
}

export function getSigner(
  wallet: ethers.HDNodeWallet,
  provider: ethers.JsonRpcProvider
): ethers.HDNodeWallet {
  return wallet.connect(provider);
}

export function getWalletFromPhrase(
  provider: ethers.JsonRpcProvider
): ethers.HDNodeWallet {
  const phrase = process.env.REACT_APP_WALLET_PHRASE!;
  return ethers.Wallet.fromPhrase(phrase).connect(provider);
}

export function getContract(
  contractAddress: string,
  contractABI: any,
  signer: ethers.Signer
): ethers.Contract {
  return new ethers.Contract(contractAddress, contractABI, signer);
}
