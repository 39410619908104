import "./ConsoleButton.css";

export enum Positions {
  LEFT,
  RIGHT,
}
interface LayoutProps {
  width: string;
  height: string;
}
interface Props {
  insideText: string;
  outsideText: string;
  outsideTextPosition: Positions;
  layout: LayoutProps;
  onClickHandler: () => void;
}

const ConsoleButton: React.FC<Props> = ({
  insideText,
  outsideText,
  outsideTextPosition,
  layout,
  onClickHandler,
}) => {
  return (
    <div className="console-button">
      {outsideTextPosition === Positions.LEFT && (
        <span
          className="outside outside-left"
          style={{
            width: layout.width,
            height: layout.height,
          }}
        >
          {outsideText}
        </span>
      )}

      <span className="inside" onClick={onClickHandler}>
        {insideText}
      </span>

      {outsideTextPosition === Positions.RIGHT && (
        <span
          className="outside outside-right"
          style={{
            width: layout.width,
            height: layout.height,
          }}
        >
          {outsideText}
        </span>
      )}
    </div>
  );
};
export default ConsoleButton;
