import React, { createContext, useReducer, Dispatch } from "react";
import { GlobalReducer } from "../store/globalReducer";
import { GlobalStore, Action } from "../interfaces/store";
import GlobalInitStore from "../store/globalStore";

interface GlobalContextProps {
  globalStore: GlobalStore;
  globalDispatch: Dispatch<Action>;
}

export const GlobalContext = createContext<GlobalContextProps>({
  globalStore: GlobalInitStore,
  globalDispatch: () => {},
});

export const GlobalReducerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [globalStore, globalDispatch] = useReducer(
    GlobalReducer,
    GlobalInitStore
  );

  return (
    <GlobalContext.Provider value={{ globalStore, globalDispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
