import "./Modal.css";

const Modal: React.FC = () => {
  return (
    <div className="modal-container">
      <h2>User Already Registered</h2>
      <p>This wallet has already been registered in the airdrop.</p>
    </div>
  );
};

export default Modal;
