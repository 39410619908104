import "./ConsoleElement.css";

interface ComponentPosition {
	component: React.ReactNode;
	top?: string;
	left?: string;
	right?: string;
	bottom?: string;
}

interface Props {
	width: string;
	height: string;
	top: string;
	left: string;
	elements: ComponentPosition[];
	rotation?: string;
}

const ConsoleElement: React.FC<Props> = ({
	width,
	height,
	top,
	left,
	elements,
	rotation = "0deg",
}) => {
	return (
		<div
			className="console-element"
			style={{
				width: width,
				height: height,
				top: top,
				left: left,
				position: "absolute",
                transform: `rotate(${rotation})`,
                transformOrigin: "center center",
			}}
		>
			{elements.map((el, index) => (
				<div
					className="micro-element"
					key={index}
					style={{
						position: "absolute",
						top: el.top,
						left: el.left,
						right: el.right,
						bottom: el.bottom,
					}}
				>
					{el.component}
				</div>
			))}
		</div>
	);
};

export default ConsoleElement;
