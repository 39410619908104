import React, { useContext, useEffect } from "react";
import Header from "../../components/common/header/Header";
import "./ComingSoonPage.css";
import PhaseAnnounce from "../../components/comingSoon/anounce/PhaseAnnounce";
import RewardMessage from "../../components/comingSoon/rewardMessage/RewardMessage";
import PhaseBoxGroup from "../../components/comingSoon/phases/phaseBoxGroup/PhaseBoxGroup";
import { useLocation } from "react-router-dom";
import { UserData } from "../../interfaces/user";
import Footer from "../../components/common/footer/Footer";
import { GlobalContext } from "../../contexts/GlobalContext";
import { setAppSection } from "../../store/globalActions";

const ComingSoonPage: React.FC = () => {
  const location = useLocation();
  const userData: UserData = location.state?.formState;
  const { globalStore, globalDispatch } = useContext(GlobalContext);

  useEffect(() => {
    globalDispatch(setAppSection("Coming Soon"));
  }, []);

  return (
    <div className="coming-soon-page-container">
      <div className="background-coming"></div>
      <div className="header-container">
        <Header />
      </div>
      <PhaseAnnounce formState={userData} />
      <RewardMessage />
      <PhaseBoxGroup />
      <Footer></Footer>
    </div>
  );
};

export default ComingSoonPage;
